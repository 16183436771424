@font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere-webfont.woff) format('woff'),
    url(/src/assets/fonts/esphimere-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format('woff'),
    url(/src/assets/fonts/esphimere_italic-webfont.woff2) format('woff2');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format('woff'),
    url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format('woff2');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format('woff'),
    url(/src/assets/fonts/esphimere_bold-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'esphimere';
    src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format('woff'),
    url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
  }

* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'esphimere', sans-serif;
}

//Variaveis
$internalSpace: 100px;


//Breakpoints
@mixin breakpoint($name) {
    @if $name =="vrsmall" {
        @media (max-width: 574px) {
            @content;
        }
    }

    @else if $name =="small" {
        @media (min-width: 576px) {
            @content;
        }
    }

    @else if $name =="mdsmall" {
        @media (min-width: 768px) {
            @content;
        }
    }

    @else if $name =="medium" {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $name =="mdlarge" {
        @media (min-width: 1200px) {
            @content;
        }
    }

    @else if $name =="large" {
        @media (min-width: 1400px) {
            @content;
        }
    }
}

//Conteiners Largura
.container {
    width: 100%;

    @include breakpoint(vrsmall) {
        margin: 0 15px;
    }

    @include breakpoint(small) {
        max-width: 540px;
    }

    @include breakpoint(mdsmall) {
        max-width: 720px;
    }

    @include breakpoint(medium) {
        max-width: 960px;
    }

    @include breakpoint(mdlarge) {
        max-width: 1140px;
    }

    @include breakpoint(large) {
        max-width: 1320px;
    }
}

//Mensagens de ERRO
.erroForm {
    color: #F9A825;
}

// INICIO - AcxHeader
#header {
    background: url(../imgs/banner.jpg) center center no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        

        @include breakpoint(vrsmall) {
            max-width: 95%;
        }

        @include breakpoint(small) {
            max-width: 90%;
        }

        @include breakpoint(mdsmall) {
            max-width: 70%;
        }

        @include breakpoint(medium) {
            max-width: 55%;
        }

        @include breakpoint(mdlarge) {
            max-width: 45%;
        }
    }

    h1 {
        font-weight: 400;
        font-style: italic;
        color: #fff;
        padding-top: 50px;

        strong {
            font-weight: 900;
            color: #D9E6B1;
        }

        @include breakpoint(vrsmall) {
            font-size: 50px;
            text-align: center;
        }

        @include breakpoint(small) {
            font-size: 58px;
            
        }

        @include breakpoint(mdsmall) {
            font-size: 61px;
            margin-left: $internalSpace;
        }

    }

}

// FIM - AcxHeader

// INICIO - BcxSectionA

#SectionA {
    background: #387F77;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        padding: $internalSpace 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(vrsmall) {
            flex-direction: column;
            gap: 50px;
        }

        @include breakpoint(small) {
            flex-direction: column;
            gap: 50px;
        }

        @include breakpoint(mdsmall) {
            flex-direction: column;
            gap: 50px;
        }

        @include breakpoint(medium) {
            flex-direction: row;
        }

        .text {

            width: 50vw;

            p {
                color: #fff;
                font-size: 28px;
                font-weight: 400;
                line-height: 40px;
                padding: 15px 0;
                max-width: 100%;
            }
        }

        .logos {
            width: 50vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            

            .credCaxias {
                width: 80%;
            }

            .parceiros {
                width: 90%;
            }
        }
    }
}

// FIM - BcxSectionA

// INICIO - BcxSectionB

#sectionB {
    padding: $internalSpace 0;
    background: url(../imgs/bg-2.png) center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {

        .title {
            font-weight: 400;
            font-style: italic;
            font-size: 75px;
            text-align: center;
            line-height: 80px;
            color: #387F77;
            margin-bottom: 180px;

            strong {
                font-weight: 600;
                font-style: italic;
                color: #013853;
            }
        }

        .cards {
            display: flex;
            justify-content: space-evenly;

            @include breakpoint(vrsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(small) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(medium) {
                flex-direction: row;
                align-items: flex-start;
            }

            .card {
                width: 300px;
                text-align: center;

                img {
                    width: 200px;
                }

                h3 {
                    font-size: 25px;
                    font-weight: 400;
                    text-align: center;
                    color: #387F77;
                    margin-top: 25px;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

// FIM - BcxSectionB

// INICIO - BcxSectionC

#sectionC {
    padding: $internalSpace 0;
    background: url(../imgs/bg-1.png) center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {
        margin-right: 0 ;

        .title {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: #D9E6B1;
            margin-bottom: 120px;

            strong {
                font-weight: 600;
                color: #ffffff;
            }

            @include breakpoint(vrsmall) {
                font-size: 55px;
            }
    
            @include breakpoint(small) {
                font-size: 75px;
            }

        }

        .cards {
            display: flex;
            justify-content: space-evenly;

            @include breakpoint(vrsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(small) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                align-items: center;
                gap: 50px;
            }

            @include breakpoint(medium) {
                flex-direction: row;
                align-items: flex-start;
            }

            .card {
                width: 300px;
                text-align: center;

                img {
                    width: 200px;
                }

                h3 {
                    font-size: 25px;
                    font-weight: 400;
                    text-align: center;
                    color: #fff;
                    margin-top: 25px;

                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .rodape {
            padding-top: 80px;

            p {
                color: #D9E6B1;
                font-size: 20px;
                font-weight: 800;
                text-align: center;
            }
        }
    }
}

// FIM - BcxSectionC

// INICIO - BcxSectionD

#sectionD {
    padding: $internalSpace 0;
    background: url(../imgs/bg-3.jpg) fixed center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    width: 100%;

    .container {
        display: flex;

        @include breakpoint(vrsmall) {
            flex-direction: column;
            align-items: center;
            gap: 100px;
        }

        @include breakpoint(small) {
            flex-direction: column;
            align-items: center;
            gap: 100px;
        }

        @include breakpoint(mdsmall) {
            flex-direction: column;
        }

        @include breakpoint(medium) {
            flex-direction: row;
            align-items: center;
            gap: 50px;
        }


        .header {            
            color: #fff;

            @include breakpoint(vrsmall) {
                width: 90vw;
            }
    
            @include breakpoint(small) {
                width: 90vw;
            }
    
            @include breakpoint(medium) {
                width: 50vw;
            }


            .titulo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                gap: 25px;

                img {
                    height: 150px;

                }
            }


            h2 {
                font-size: 41px;
                font-weight: 400;
                margin: 60px 0;

                strong {
                    font-weight: 600;
                    font-style: italic;
                }
            }

            p {
                font-size: 23px;
                font-weight: 400;
                line-height: 30px;
                margin: 25px 0 0 0;

                @include breakpoint(vrsmall) {
                    text-align: center;
                }
        
                @include breakpoint(small) {
                    text-align: center;
                }
        
                @include breakpoint(medium) {
                    text-align: left;
                }
            }
        }

        .content {
            @include breakpoint(vrsmall) {
                width: 90vw;
            }
    
            @include breakpoint(small) {
                width: 90vw;
            }
    
            @include breakpoint(medium) {
                width: 50vw;
            }           

            .col {                
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h3 {                    
                    color: #fff;
                    margin-bottom: 15px;
                    text-align: center;

                    @include breakpoint(vrsmall) {
                        font-weight: 500;
                        font-size: 23px;
                    }
            
                    @include breakpoint(small) {
                        font-weight: 500;
                        font-size: 23px;
                    }
            
                    @include breakpoint(medium) {
                        font-weight: 600;
                        font-size: 28px;
                    }   
                }

                a.btn {
                    text-decoration: none;
                    color: #013853;
                    font-size: 23px;
                    font-weight: 500;
                    margin-bottom: 30px;
                    background-color: #D9E6B1;                    
                    text-align: center;
                    border-radius: 30px;
                    transition: color .40s ease-in-out, background-color .40s ease-in-out;

                    &:hover {
                        color: #D9E6B1;
                        background-color: #013853;
                        transition: color .40s ease-in-out, background-color .40s ease-in-out;
                    }

                    @include breakpoint(vrsmall) {
                        padding: 20px 100px;
                    }
            
                    @include breakpoint(small) {
                        padding: 20px 150px;
                    }
                }

                .btn {

                    cursor: pointer;
                }
            }
        }
    }
}

// FIM - BcxSectionD

// INICIO - BcxSectionE

#sectionE {
    background: url(../imgs/bg-4.jpg) top center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;


    .container {
        padding: $internalSpace 0;
        display: flex;
        align-items: center;
        flex-direction: column;


        .title {
            font-weight: 400;
            font-size: 75px;
            text-align: center;
            color: #fff;
            margin-bottom: 80px;

            strong {
                font-weight: 600;
                color: #D9E6B1;

            }
        }

        .rowCard {
            max-width: 1100px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px 0;

            @include breakpoint(vrsmall) {
                flex-direction: column;
            }

            @include breakpoint(small) {
                flex-direction: column;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }


            .texto {

                @include breakpoint(vrsmall) {
                    max-width: 100%;
                }

                @include breakpoint(small) {
                    max-width: 100%;
                }

                @include breakpoint(medium) {
                    max-width: 50%;
                }

                h3 {
                    font-weight: 400;
                    color: #D9E6B1;

                    @include breakpoint(vrsmall) {
                        font-size: 33px;
                    }

                    @include breakpoint(small) {
                        font-size: 43px;
                    }

                    strong {
                        font-weight: 600;
                        font-style: italic;
                    }
                }

                p {
                    font-size: 25px;
                    margin-top: 30px;
                    color: #fff;
                    font-weight: 400;

                    strong {
                        color: #D9E6B1;
                        font-weight: 600;
                    }
                }
            }
        }

        .micInd,
        .peqEmp {

            @include breakpoint(vrsmall) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(small) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(mdsmall) {
                flex-direction: column;
                text-align: center;
            }

            @include breakpoint(medium) {
                flex-direction: row-reverse;
                text-align: left;
            }
        }

        .micEmp {

            @include breakpoint(vrsmall) {
                text-align: center;
            }

            @include breakpoint(small) {
                text-align: center;
            }

            @include breakpoint(mdsmall) {
                text-align: center;
            }

            @include breakpoint(medium) {
                text-align: right;
            }
        }
    }

    .imagem img {
        max-width: 100%;
    }

    .aviso {
        width: 70%;
        margin-top: $internalSpace;
        background-color: #D9E6B1;
        font-size: 20px;
        line-height: 30px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(vrsmall) {
            flex-direction: column;
            padding: 30px 25px;
            gap: 25px;
        }

        @include breakpoint(small) {
            flex-direction: column;
            padding: 30px 25px;
            gap: 25px;
        }

        @include breakpoint(mdsmall) {
            padding: 30px 40px;
            flex-direction: row;
            gap: 50px;
        }

        .imgAviso {
            text-align: right;
        }

        .textoAviso {
            color: #008077;
        }
    }
}

// FIM - BcxSectionE

// INICIO - BcxSectionF

#sectionF {
    display: flex;
    flex-direction: column;
    background: #D9E6B1;
    background-size: cover;

    .title {
        padding: $internalSpace 0 0 0;
            h2{
                font-weight: 400;
                text-align: center;
                color: #008077;
                margin-bottom: 80px;
                font-style: italic;
            
                strong {
                    font-weight: 600;
                    color: #013853;

                }

                @include breakpoint(vrsmall) {
                    font-size: 53px;
                }
        
                @include breakpoint(small) {
                    font-size: 75px;
                }
            }
            
    }

    .containerF {
        width: 100vw;
        display: flex;
        flex-direction: row;     
        

        @include breakpoint(vrsmall) {
            padding: 0 50px 0 50px;
        }

        @include breakpoint(small) {
            padding: 0 $internalSpace 0 $internalSpace;
        }

        .texto {
            
            a{
                background-color: #008077;
                color: #fff;
                text-decoration: none;
                padding: 5px 20px;
                border-radius: 20px;
                font-weight: 500;
                margin-left: 10px;

                &:hover{
                    background-color: #013853;
                }
            }

            h2 {
                font-weight: 300;
                font-size: 43px;
                color: #fff;
                margin-bottom: 30px;

                strong {
                    font-weight: 900;
                }
            }

            h3 {
                color: #fff;
            }

            .tipoEmpresa {
                color: #008077;
                font-weight: 600;

                span{
                    margin-bottom: 50px;
                }
            }

            p {
                font-size: 20px;
                line-height: 25.5px;
                margin-bottom: 30px;
                font-weight: 400;
                color: #013853;
            }

            ul {
                margin-top: 20px;
                list-style-position: inside;
                padding-left: 0;
              }

            li {
                list-style: none;
                color: #013853;
                font-weight: 400;
            }

            .asterisco{
                font-size: 18px;
                font-weight: 400;
                font-style: italic;
                color: #008077;
            }
        }

        .imagem {            
            align-self: flex-end;

            @include breakpoint(vrsmall) {
                display: none;
            }

            @include breakpoint(small) {
                display: none;
            }


            @include breakpoint(mdlarge) {
                display: block;
            }


            img {
                margin-bottom: -4px;
            }
        }
    }
}

// FIM - BcxSectionF

// INICIO - BcxSectionG
#sectionG {
    background: url(../imgs/bg-g.jpg) center center no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .container {
        padding: $internalSpace 0;

        .title {
            font-weight: 400;
            font-style: italic;
            text-align: center;
            color: #ffffff;
            margin-bottom: 80px;

            strong {
                font-weight: 600;
                color: #D9E6B1;
            }

            @include breakpoint(vrsmall) {
                font-size: 55px;
            }
    
            @include breakpoint(small) {
                font-size: 75px;
            }
        }

        .duvida {
            padding: 40px;
            margin: 15px 0px;
            border: 2px solid #D9E6B1;
            border-radius: 90px;
            text-align: center;
            min-height: 190px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                font-size: 25px;
                color: #D9E6B1;
                font-weight: 900;
            }

            p {
                font-size: 18px;
                color: #ffffff;
                font-weight: 400;
                margin-top: 15px;
            }
        }

        h4 {
            max-width: 1000px;
            margin: 0 auto;
            font-size: 25px;
            color: #fff;
            font-weight: 600;
            font-style: italic;
            padding-top: $internalSpace;
            text-align: center;
        }
    }
}

// FIM - BcxSectionG

// INICIO - Footer

#footer {
    display: flex;
    justify-content: center;

    .container {

        .logos {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;


            img {
                @include breakpoint(vrsmall) {
                    max-width: 180px;
                }

                @include breakpoint(small) {
                    max-width: 150px;
                }

                @include breakpoint(mdsmall) {
                    max-width: 140px;
                }

                @include breakpoint(medium) {
                    max-width: 180px;
                }

                @include breakpoint(mdlarge) {
                    max-width: 200px;
                }
            }
        }
    }
}

// FIM - Footer

// INICIO - Formulario

#sectionForm {
    background: url(../imgs/bg-f.jpg) top center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $internalSpace;



    .formHeader {

        @include breakpoint(vrsmall) {
            display: flex;
            flex-direction: column;
            padding: 0 0 80px 0;
            align-items: center;
            width: 100%;

            h4 {
                text-align: center;
                padding: 25px 0 0 0;
            }
        }

        @include breakpoint(small) {
            display: flex;
            flex-direction: column;
            padding: 0 0 80px 0;
            align-items: center;
            width: 100%;

            h4 {
                text-align: center;
                padding: 25px 0 0 0;
            }
        }

        @include breakpoint(mdsmall) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 1320px;
            width: 100%;

            h4 {
                text-align: right;
            }

        }

        @include breakpoint(medium) {}

        @include breakpoint(mdlarge) {}

        .fHeaderTitle,
        h4 {
            font-weight: 300;
            color: #fff;
        }

        .fHeaderTitle {
            font-size: 43px;
        }



    }

    form {

        @include breakpoint(vrsmall) {
            width: 350px;
        }

        @include breakpoint(small) {
            width: 540px;
        }

        @include breakpoint(mdsmall) {
            width: 720px;
        }

        @include breakpoint(medium) {
            width: 960px;
        }

        @include breakpoint(mdlarge) {
            width: 1140px;
        }

        @include breakpoint(large) {
            width: 1320px;
        }

        .btnSend {
            text-align: center;
            padding: 50px 0 0 0;
            

            Button {
                cursor: pointer;
                width: 75%;
                padding: 15px 0;
                border-radius: 50px;
                font-weight: 500;
                font-size: 20px;
            }
        }
    }


    .fileUpload {
        margin-top: 50px;

        h4 {
            padding: 10px 0 10px 30px;
            color: #fff;
        }

        p {
            padding: 10px 0 10px 30px;
            color: rgb(162, 162, 162);
        }

        .erroForm {
            margin-left: 30px;
        }

        .uploadAnexo {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 50px;

            .campoStyle {
                width: 100%;
                padding: 15px;
                color: #00a0a9;
                font-size: 16px;
                font-weight: 700;
            }


        }
    }



    .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
        column-gap: 10px;
        row-gap: 20px;

        .personalColors {
            color: #fff;
        }

        .campoStyle {
            background-color: rgba(255, 255, 255, 1);
            max-height: 55px;
            border-radius: 50px;
            width: 100%;

            ::before,
            ::after {
                border: 0px;
            }

            label {
                font-family: "Montserrat", sans-serif;
                color: rgb(0, 0, 0);
                font-weight: 500;
            }
        }

        .radioStyle {
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 50px;
            max-height: 80px;

            span:nth-child(1) {
                color: #0d6efd;
            }

            label {
                color: #fff;
            }

        }

        @include breakpoint(vrsmall) {
            width: inherit;

            .Empresa,
            .CNPJ,
            .Telefone,
            .CEP,
            .Endereco,
            .Bairro,
            .E-mail,
            .Faturamento,
            .Porte,
            .Atividade,
            .Associado,
            .Agencia {
                grid-column: 1 / 13;
            }


            .Estado {
                grid-column: 1 / 7;
            }

            .Cidade {
                grid-column: 7 / 13;
            }

            .Porte,
            .Atividade,
            .Associado {
                align-items: center;
            }

            .Agencia {
                text-align: center;
            }

            //socio

            .cpfSocio1 {
                grid-column: 1 / 13;
            }

            .nomeSocio1 {
                grid-column: 1 / 13;
            }

            .emailSocio1 {
                grid-column: 1 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 7;
            }

            .nascimentoSocio1 {
                grid-column: 7 / 13;
            }

            .nomemaeSocio1 {
                grid-column: 1 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 7;
            }

            .sexoSocio1 {
                grid-column: 7 / 13;
            }

            .naturalidadeSocio1 {
                grid-column: 1 / 13;
            }

            .cepSocio1 {
                grid-column: 1 / 13;
            }

            .enderecoSocio1 {
                grid-column: 1 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 13;
            }

            .estadoSocio1 {
                grid-column: 1 / 5;
            }

            .cidadeSocio1 {
                grid-column: 5 / 13;
            }

            .sociedadeSocio1 {
                grid-column: 3 / 11;
            }

            .cidadeSocio2 {
                grid-column: 5 / 13;
            }

            .sociedadeSocio2 {
                grid-column: 3 / 11;
            }

            .complementoSocio1 {
                grid-column: 1 / 13;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                padding: 0 20px;
                align-items: center;
                justify-content: center;
                min-height: 150px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            //Conjuge
            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 1 / 13;
            }

            .rendaCj {
                grid-column: 1 / 13;
            }

            //Crédito Pretendido

            .valorCredito {
                grid-column: 1 / 13;
            }

            .nmParcelas {
                grid-column: 1/ 7;
            }

            .carencia {
                grid-column: 7 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;
                row-gap: 20px;

                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregos {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include breakpoint(small) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 8;
            }

            .CNPJ,
            .Endereco {
                grid-column: 8 / 13;
            }

            .Telefone,
            .Bairro {
                grid-column: 1 / 5;
            }

            .CEP {
                grid-column: 5 / 8;
            }

            .Estado {
                grid-column: 5 / 9;
            }

            .Cidade {
                grid-column: 9 / 13;
            }

            .E-mail {
                grid-column: 1 / 6;
            }

            .Faturamento {
                grid-column: 6 / 13;
            }

            .Porte,
            .Atividade,
            .Associado {
                grid-column: 1 / 13;
                align-items: center;
            }

            .Agencia {
                grid-column: 1 / 13;
                text-align: center;
            }

            //socio

            .cpfSocio1 {
                grid-column: 1 / 5;
            }

            .nomeSocio1 {
                grid-column: 5 / 13;
            }

            .emailSocio1 {
                grid-column: 1 / 9;
            }

            .telefoneSocio1 {
                grid-column: 9 / 13;
            }

            .nascimentoSocio1 {
                grid-column: 1 / 5;
            }

            .nomemaeSocio1 {
                grid-column: 5 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 5;
            }

            .sexoSocio1 {
                grid-column: 5 / 8;
            }

            .naturalidadeSocio1 {
                grid-column: 8 / 13;
            }

            .cepSocio1 {
                grid-column: 1 / 5;
            }

            .enderecoSocio1 {
                grid-column: 5 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 5;
            }

            .estadoSocio1 {
                grid-column: 5 / 8;
            }

            .cidadeSocio1 {
                grid-column: 8 / 13;
            }

            .sociedadeSocio1 {
                grid-column: 4 / 10;
            }

            .cidadeSocio2 {
                grid-column: 8 / 13;
            }

            .sociedadeSocio2 {
                grid-column: 8 / 13;
            }

            .complementoSocio1 {
                grid-column: 1 / 8;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                padding: 0 20px;
                align-items: center;
                justify-content: center;
                min-height: 150px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            //Conjuge
            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 8 / 13;
            }

            .rendaCj {
                grid-column: 4 / 10;
            }

            //Crédito Pretendido

            .valorCredito {
                grid-column: 1 / 13;
            }

            .nmParcelas {
                grid-column: 1 / 7;
            }

            .carencia {
                grid-column: 7 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;
                row-gap: 20px;

                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: flex;
                    flex-direction: column;
                    align-self: center;

                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregos {
                        margin-top: 20px;
                    }
                }
            }
        }

        @include breakpoint(mdsmall) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 7;
            }

            .CNPJ {
                grid-column: 7 / 10;
            }

            .Telefone,
            .Bairro {
                grid-column: 10 / 13;
            }

            .CEP,
            .Estado {
                grid-column: 1 / 5;
            }

            .Endereco {
                grid-column: 5 / 10;
            }

            .Cidade {
                grid-column: 5 / 8;
            }

            .E-mail {
                grid-column: 8 / 13;
            }

            .Faturamento {
                grid-column: 1 / 7;
            }

            .Porte,
            .Associado {
                grid-column: 7 / 13;
                align-items: center;
            }

            .Atividade {
                grid-column: 1 / 7;
                align-items: center;
            }

            .Agencia {
                grid-column: 2 / 12;
                text-align: center;
            }

            //socio

            .cpfSocio1 {
                grid-column: 1 / 4;
            }

            .nomeSocio1 {
                grid-column: 4 / 9;
            }

            .emailSocio1 {
                grid-column: 9 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 4;
            }

            .nascimentoSocio1 {
                grid-column: 4 / 7;
            }

            .nomemaeSocio1 {
                grid-column: 7 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 4;
            }

            .sexoSocio1 {
                grid-column: 4 / 6;
            }

            .naturalidadeSocio1 {
                grid-column: 6 / 9;
            }

            .cepSocio1 {
                grid-column: 9 / 13;
            }

            .enderecoSocio1 {
                grid-column: 1 / 7;
            }

            .bairroSocio1 {
                grid-column: 7 / 11;
            }

            .estadoSocio1 {
                grid-column: 11 / 13;
            }

            .cidadeSocio1 {
                grid-column: 1 / 10;
            }

            .sociedadeSocio1 {
                grid-column: 10 / 13;
            }

            .cidadeSocio2 {
                grid-column: 1 / 5;
            }

            .sociedadeSocio2 {
                grid-column: 10 / 13;
            }

            .complementoSocio1 {
                grid-column: 5 / 10;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                align-items: center;
                justify-content: center;
                min-height: 100px;

                .estadoCivilRadio {
                    display: flex;
                    justify-content: center;
                }
            }

            //Conjuge
            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 10 / 13;
            }

            .rendaCj {
                grid-column: 4 / 10;
            }

            //Crédito Pretendido

            .valorCredito {
                grid-column: 1 / 6;
            }

            .nmParcelas {
                grid-column: 6 / 9;
            }

            .carencia {
                grid-column: 9 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;


                .fCredito {
                    grid-column: 1 / 13;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 1 / 13;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas  
                    column-gap: 10px;
                    align-items: center;
                    width: 100%;


                    .quantosEmpregados,
                    .quantosEmpregos {
                        width: 100%;
                    }

                    .quantosEmpregados {
                        grid-column: 1 / 7;
                    }

                    .quantosEmpregos {
                        grid-column: 7 / 13;
                        margin-top: 0;
                    }
                }
            }
        }

        @include breakpoint(medium) {
            width: inherit;

            .Empresa {
                grid-column: 1 / 7;
            }

            .CNPJ,
            .Bairro {
                grid-column: 7 / 10;
            }

            .CEP,
            .Cidade {
                grid-column: 1 / 3;
            }

            .Endereco {
                grid-column: 3 / 7;
            }

            .Estado,
            .Telefone {
                grid-column: 10/13;
            }

            .E-mail {
                grid-column: 3 / 6;
            }

            .Faturamento {
                grid-column: 6 / 10;
            }

            .Porte {
                grid-column: 10 / 13;
                padding: 0 0 0 5px;
            }

            .Atividade {
                grid-column: 1 / 6;
                align-self: center;
                padding: 5px 0;
            }

            .Associado {
                grid-column: 6 / 9;
                align-self: center;
                padding: 5px 0;
            }

            .Agencia {
                grid-column: 9 / 13;
            }

            //socio

            .cpfSocio1 {
                grid-column: 1 / 4;
            }

            .nomeSocio1 {
                grid-column: 4 / 10;
            }

            .emailSocio1 {
                grid-column: 10 / 13;
            }

            .telefoneSocio1 {
                grid-column: 1 / 4;
            }

            .nascimentoSocio1 {
                grid-column: 4 / 7;
            }

            .nomemaeSocio1 {
                grid-column: 7 / 13;
            }

            .rgSocio1 {
                grid-column: 1 / 3;
            }

            .sexoSocio1 {
                grid-column: 3 / 4;
            }

            .naturalidadeSocio1 {
                grid-column: 4 / 6;
            }

            .cepSocio1 {
                grid-column: 6 / 8;
            }

            .enderecoSocio1 {
                grid-column: 8 / 13;
            }

            .bairroSocio1 {
                grid-column: 1 / 5;
            }

            .estadoSocio1 {
                grid-column: 5 / 7;
            }

            .cidadeSocio1 {
                grid-column: 7 / 10;
            }

            .sociedadeSocio1 {
                grid-column: 10 / 13;
            }

            .cidadeSocio2 {
                grid-column: 7 / 10;
            }

            .sociedadeSocio2 {
                grid-column: 5 / 10;
            }

            .complementoSocio1 {
                grid-column: 10 / 13;
            }

            .estadoCivilSocio1 {
                grid-column: 1 / 13;
                align-items: center;
            }

            //Conjuge
            .conjugeFields {
                grid-column: 1 / 13;
            }

            .profissaoCj {
                grid-column: 1 / 7;
            }

            .rendaCj {
                grid-column: 7 / 13;
            }

            //Crédito Pretendido

            .valorCredito {
                grid-column: 1 / 5;
            }

            .nmParcelas {
                grid-column: 5 / 8;
            }

            .carencia {
                grid-column: 8 / 13;
            }

            .creditoEmprego {
                display: grid;
                grid-column: 1 / 13;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas
                column-gap: 10px;


                .fCredito {
                    grid-column: 1 / 7;

                    .finalidadeCredito {
                        width: 100%;
                        padding: 20px 0 0 20px;
                        min-height: 330px;

                        label {
                            margin-bottom: 20px;
                        }

                        .finalidadeCreditoRadio {
                            font-weight: bold;

                            label {
                                margin: 0;
                            }
                        }
                    }
                }

                .fEmpregos {
                    grid-column: 7 / 13;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; //12 Colunas  
                    column-gap: 10px;
                    row-gap: 20px;
                    align-items: center;
                    width: 100%;


                    .quantosEmpregados,
                    .quantosEmpregos {
                        grid-column: 3 / 13;
                        width: 100%;
                    }
                }
            }


        }

        @include breakpoint(mdlarge) {
            width: inherit;
        }

        @include breakpoint(large) {
            width: inherit;
        }
    }

    .socioTitle {
        border-bottom: 2px solid #fff;
        color: #ffffff;
        margin: 50px 0;
        padding: 10px 0;
        width: inherit;
    }

    .conjugeTitle {
        color: #ffffff;
        margin: 50px 0;
        padding: 10px 0;
        width: inherit;

        h2 {
            border-bottom: 2px solid #fff;
            padding-bottom: 10px;
            margin-bottom: 50px;
        }
    }

    // SocioEXTRA

    .addSocio {
        width: 100%;
        margin: 50px 0;
        padding: 25px 0;
        background-color: #F9A825;
        font-size: 18px;
        color: #000;

        &:hover {
            background-color: #F57F17;
            color: #fff;
        }
    }

    .exclusaoSocio {
        width: 100%;
        display: flex;
        justify-content: right;

        .excluiSocio {
            margin: 0 0 25px 0;
            background-color: #C62828;
            padding: 15PX 10PX;

            &:hover {
                background-color: #D50000;
            }
        }
    }


}

// INICIO - TERMOS

.termosAceite {
    margin: 50px 0;


    .termosGroup {
        display: flex !important;
        flex-direction: column !important;
        gap: 25px !important;
        color: #fff;
        font-family: Roboto, Helvetica, Arial, sans-serif;

        .politicaCheck {
            display: flex;
            align-items: center;



            .checkbox {
                svg {
                    margin-right: 15px;
                }
            }

            p {
                a {
                    color: #1976d2;
                    font-weight: 500;
                }
            }
        }


    }



    .checkbox {
        padding: 25px 0 25px 15px;

        svg {
            color: #fff;
            margin-right: 25px;
        }

    }

    .popupctd {
        position: relative;
        color: #1a1a1a;
        max-width: 100%;


        h3 {
            padding-bottom: 15px;
        }

        p {
            margin-bottom: 15px;
        }
    }

    .docSolicita,
    .docAnalise {
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        color: #fff;
        font-size: 30px;
        
    }

    .docSolicita {
        padding: 50px 0 50px 0;
        font-style: italic;
        font-weight: 400;
        color: #D9E6B1;
    }

    .docAnalise {
        font-weight: 600;
        font-style: italic;
    }
}



// INICIO - POPUPS

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.50);
    display: flex;
    justify-content: center;
    z-index: 10000;
    overflow-y: scroll;

    &-inner {
        position: relative;
        padding: 32px;
        height: max-content !important;
        max-width: 1000px;
        background-color: #fff;

        .close-btn {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
}