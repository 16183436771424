@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_italic-webfont.woff2) format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_semi_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_semi_bold-webfont.woff2) format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "esphimere";
  src: url(/src/assets/fonts/esphimere_bold_italic-webfont.woff) format("woff"), url(/src/assets/fonts/esphimere_bold_italic-webfont.woff2) format("woff2");
  font-weight: 600;
  font-style: italic;
}
body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "esphimere", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

